import React, { useState, useEffect } from "react";

const Work = () => {
  const [visibleProjectsCount, setVisibleProjectsCount] = useState(4); // Initially show 3 projects

  useEffect(() => {
    // Function to update the project count based on screen size
    const updateProjectCount = () => {
      if (window.innerWidth >= 768) {
        setVisibleProjectsCount(6); // Desktop view
      } else {
        setVisibleProjectsCount(4); // Mobile view
      }
    };

    // Add event listener to update project count on window resize
    window.addEventListener("resize", updateProjectCount);

    // Set initial project count based on current window size
    updateProjectCount();

    // Cleanup event listener on component unmount
    return () => window.removeEventListener("resize", updateProjectCount);
  }, []);

  const handleLoadMoreClick = () => {
    setVisibleProjectsCount(projects.Apps.length); // Show all projects
  };

  // Sample projects data for the "Apps" category
  const projects = {
    Apps: [
      {
        title: "PetDialog Studies App",
        type: "Apps",
        imageUrl: `${process.env.PUBLIC_URL}/assets/images/petDialog.webp`,
        link: "https://apps.apple.com/us/app/petdialog-studies-app/id1462174929"
      },
      {
        title: "ICARUS OPS",
        type: "Apps",
        imageUrl: `${process.env.PUBLIC_URL}/assets/images/icarus.webp`,
        link: "https://apps.apple.com/in/app/icarus-ops/id1459253884"
      },
      {
        title: "UMANG",
        type: "Apps",
        imageUrl: `${process.env.PUBLIC_URL}/assets/images/umang.webp`,
        link: "https://apps.apple.com/in/app/umang/id1236448857"
      },
      {
        title: "Parklinq - Hawaii Parking App",
        type: "Apps",
        imageUrl: `${process.env.PUBLIC_URL}/assets/images/parklinq.webp`,
        link: "https://apps.apple.com/us/app/parklinq-hawaii-parking-app/id1347266307"
      },
      {
        title: "ListerPros",
        type: "Apps",
        imageUrl: `${process.env.PUBLIC_URL}/assets/images/listenPro.webp`,
        link: "https://apps.apple.com/us/app/listerpros/id6474097058"
      },
      {
        title: "Vision Time Tracking",
        type: "Apps",
        imageUrl: `${process.env.PUBLIC_URL}/assets/images/vtt.jpg`,
        link: "https://apps.apple.com/us/app/vision-time-tracking/id6476542026"
      },
      {
        title: "Adrienne's Awesome Energy",
        type: "Apps",
        imageUrl: `${process.env.PUBLIC_URL}/assets/images/adrine.webp`,
        link: "https://apps.apple.com/in/app/adriennes-awesome-energy/id1521991727"
      },
      {
        title: "iQadha",
        type: "Apps",
        imageUrl: `${process.env.PUBLIC_URL}/assets/images/iqadha.webp`,
        link: "https://apps.apple.com/us/app/iqadha/id1395338502"
      },
      {
        title: "Tipl Rewards",
        type: "Apps",
        imageUrl: `${process.env.PUBLIC_URL}/assets/images/tiple.webp`,
        link: "https://iphone.apkpure.com/app/tipl-rewards/com.app.tipl"
      },
      {
        title: "Choueifat",
        type: "Apps",
        imageUrl: `${process.env.PUBLIC_URL}/assets/images/choueifat.webp`,
        link: "https://iphone.apkpure.com/app/choueifat/com.portalys.choueifat"
      }
    ]
  };

  const renderProjects = () => {
    return projects.Apps.slice(0, visibleProjectsCount).map(
      (project, index) => (
        <div
          className="custom-col-6 col-md-4 animate-box"
          key={index}
          data-animate-effect="fadeInLeft"
        >
          <div
            className="project"
            style={{
              backgroundImage: `url('${project.imageUrl}')`,
              height: "200px",
              width: "200px"
            }}
          >
            <div className="desc">
              <div className="con">
                <span>{project.type}</span>
                <h3>
                  <a href="{project.link}">{project.title}</a>
                </h3>
                <div style={{ flexGrow: 2,alignItems:"end",display:"flex",justifyContent:'end' }}>
                  <div style={{ textAlign: "center" }}>
                    <button
                      className="btn btn-xs"
                      style={{ borderRadius: "5px" }}
                      onClick={() => window.open(project.link, "_blank")}
                    >
                      Visit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    );
  };

  return (
    <section className="colorlib-work" data-section="work" id="work">
      <div className="colorlib-narrow-content">
        <div className="row">
          <div
            className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box"
            data-animate-effect="fadeInLeft"
          >
            <span className="heading-meta">My Work</span>
            <h2 className="colorlib-heading animate-box">Recent Work</h2>
          </div>
        </div>
        <div
          className="row row-bottom-padded-sm animate-box"
          data-animate-effect="fadeInLeft"
        >
          <div className="col-md-12">
            <p className="work-menu">
              <span>
                <a href="#work" className="active">
                  Apps
                </a>
              </span>
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 animate-box">
            <div className="row custom-row">{renderProjects()}</div>
          </div>
        </div>
        {visibleProjectsCount < projects.Apps.length && (
          <div className="row">
            <div className="col-md-12 animate-box">
              <p>
                <button
                  onClick={handleLoadMoreClick}
                  className="btn btn-primary btn-lg btn-load-more"
                >
                  Load more <i className="icon-reload" />
                </button>
              </p>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default Work;

import Aside from "./components/sections/Aside";
import Main from "./components/Main";
import LoadScripts from './components/LoadScripts';

function App() {
  return (
     <>
      <div id="colorlib-page">
      <div className="container-wrap">
         <Aside />
        <div id="colorlib-main">
          <Main />
        </div>
      </div>
    </div>
     <LoadScripts />
    </>
  );
}

export default App;

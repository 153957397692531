import React from "react";

const Certification = () => {
  return (
    <section
      className="colorlib-certifications"
      data-section="certifications"
      id="certifications"
    >
      <div className="col-md-12">
        <div
          className="about-desc animate-box"
          data-animate-effect="fadeInDown"
        >
          <span className="heading-meta">Certifications</span>
          <h1 className="certifications">
            <i className="icon-starburst" style={{ color: "#4fc1e9" }}></i>
            Certifications
          </h1>
          <div className="row row-pt-md">
            <div className="col-md-6 text-center animate-box">
              <div className="services color-1">
                <span className="text-center">
                  <i className="fa fa-award"></i>
                </span>
                <div className="desc">
                  <h3 className="text-center">O Level from NIELIT</h3>
                  <p>
                    During my O Level from NIELIT, I studied essential subjects
                    such as programming languages, computer fundamentals, and
                    database management systems. I also explored topics in web
                    designing, multimedia, and software engineering. The
                    curriculum included practical training in operating systems,
                    data communication, and networking. This certification
                    provided me with a solid foundation in both theoretical and
                    practical aspects of information technology.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Certification;

import React from "react";
import Home from "./sections/Home";
import About from "./sections/About";
import Service from "./sections/Services";
import Skill from "./sections/Skills";
import Education from "./sections/Education";
import Experience from "./sections/Experience";
import Work from "./sections/Work";
import Blog from "./sections/Blogs";
import Contact from "./sections/Contact";
import Achivements from "./sections/Achivements";
import Certification from "./sections/Certification";

const Main = () => {
  return(
    <>
    <Home />
    <div className="inner-container">
      <About />
      <Service />
      <Skill />
      <Education />
      <Experience />
      <Work />
      {/* <Blog /> */}
      <Certification/>
      <Achivements />
      <Contact />
    </div>
    
  </>
  );
};
export default Main;

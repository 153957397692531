import React, { useRef, useState } from "react";
import emailjs from '@emailjs/browser';

const Contact = () => {
  const [submitted, setSubmitted] = useState(false);
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_srm544d",
        "template_dqw0x91",
        form.current,
        "Ph89L6zGnW2AV5nc8"
      )
      .then(
        (result) => {
          console.log(result.text);
          setSubmitted(true);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <section className="colorlib-contact" data-section="contact" id="contact">
      <div className="colorlib-narrow-content">
        <div className="row">
          <div
            className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box"
            data-animate-effect="fadeInLeft"
          >
            <span className="heading-meta">Get in Touch</span>
            <h2 className="colorlib-heading">Contact</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-5">
            <div
              className="colorlib-feature colorlib-feature-sm animate-box"
              data-animate-effect="fadeInLeft"
            >
              <div className="colorlib-icon">
                <i className="icon-mail6" />
              </div>
              <div className="colorlib-text">
                <p>
                  <a href="mailto:pradeepsingh051991@gmail.com">
                    pradeepsingh051991@gmail.com
                  </a>
                </p>
              </div>
            </div>
            <div
              className="colorlib-feature colorlib-feature-sm animate-box"
              data-animate-effect="fadeInLeft"
            >
              <div className="colorlib-icon">
                <i className="icon-whatsapp" />
              </div>
              <div className="colorlib-text">
                <p>
                  <a href="tel:+919988198357">+91-99881-98357</a>
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-7 col-md-push-1">
            <div className="row">
              <div
                className="col-md-10 col-md-offset-1 col-md-pull-1 animate-box"
                data-animate-effect="fadeInRight"
              >
                {!submitted ? (
                  <form ref={form} onSubmit={sendEmail}>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        name="from_name"
                        placeholder="Name"
                        required
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        name="from_email"
                        placeholder="Email"
                        required
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        name="subject"
                        placeholder="Subject"
                        required
                      />
                    </div>
                    <div className="form-group">
                      <textarea
                        id="message"
                        cols={30}
                        rows={7}
                        className="form-control"
                        name="message"
                        placeholder="Message"
                        required
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="submit"
                        className="btn btn-primary btn-send-message"
                        value="Send Message"
                      />
                    </div>
                  </form>
                ) : (
                  <div>
                    <h2>Thank you for your message!</h2>
                    <p>We'll get back to you as soon as possible.</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;

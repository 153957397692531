import React from "react";

const Achievements = () => {
  return (
    <section className="colorlib-achivements" data-section="achivements" id="achivements">
      <div className="col-md-12">
        <div className="about-desc animate-box"  data-animate-effect="fadeInLeft">
          <span className="heading-meta">Achievements</span>
          <h1 className="achivements"><i className="icon-gift"></i>Achievements</h1>

          <div className="container">
            <div className="timeline-block timeline-block-right animate-box"  data-animate-effect="fadeInRight">
              <div className="marker"></div>
              <div className="timeline-content">
                <h4 className="examiner"><span><i className="icon-clipboard"></i></span> <strong>External Viva Examiner</strong></h4>
                <p>
                I had the honor of serving as an external viva examiner for software engineering students at Lovely Professional University, where I evaluated and provided valuable feedback on their final projects. This achievement underscored my expertise and commitment to nurturing future talent in the field.
                </p>
              </div>
            </div>

            <div className="timeline-block timeline-block-left animate-box"  data-animate-effect="fadeInLeft">
              <div className="marker"></div>
              <div className="timeline-content">
                <h4 className="recuirment"><span><i className="icon-upload"></i></span> <strong>Recruitment Drives</strong></h4>
                <p>
                I achieved a major milestone by successfully spearheading a recruitment drive for intern software engineers. Through strategic outreach and rigorous selection processes, I attracted top talent and onboarded highly skilled interns who significantly contributed to our projects, boosting overall team productivity and innovation.
                </p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
};

export default Achievements;

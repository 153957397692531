import React, { useEffect } from 'react';

const LoadScripts = () => {
  useEffect(() => {
    const loadScript = (src) => {
      return new Promise((resolve, reject) => {
        if (document.querySelector(`script[src="${src}"]`)) {
          resolve();
          return;
        }

        const script = document.createElement('script');
        script.src = src;
        script.async = true;
        script.onload = resolve;
        script.onerror = reject;
        document.body.appendChild(script);
      });
    };

    const scripts = [
      `${process.env.PUBLIC_URL}/assets/js/main.js`,
    ];

    const loadAllScripts = async () => {
      for (const script of scripts) {
        try {
          await loadScript(script);
        } catch (error) {
          console.error(`Failed to load script ${script}:`, error);
        }
      }
    };

    loadAllScripts();
  }, []);

  return null;
};

export default LoadScripts;

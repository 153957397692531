import React from "react";

const Services = () => {
  return (
    <>
      <section
        className="colorlib-services"
        data-section="services"
        id="services"
      >
        <div className="colorlib-narrow-content">
          <div className="row">
            <div
              className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box"
              data-animate-effect="fadeInLeft"
            >
              <span className="heading-meta">What I do?</span>
              <h2 className="colorlib-heading">
                Here are some of my expertise
              </h2>
            </div>
          </div>
          <div className="row row-pt-md">
            <div className="col-md-4 text-center animate-box">
              <div className="services color-1">
                <span className="icon">
                  <i className="icon-bulb"></i>
                </span>
                <div className="desc">
                  <h3>Innovative Ideas</h3>
                  <p>
                    Specialize in creating new and clever ideas that lead to the
                    development of innovative software.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 text-center animate-box">
              <div className="services color-4">
                <span className="icon">
                  <i className="icon-layers2"></i>
                </span>
                <div className="desc">
                  <h3>Requirements Analysis</h3>
                  <p>
                    Ensuring smooth software development by expertly analyzing
                    requirements for client satisfaction.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 text-center animate-box">
              <div className="services color-2">
                <span className="icon">
                  <i className="icon-brush"></i>
                </span>
                <div className="desc">
                  <h3>UI/UX</h3>
                  <p>
                    Crafting user-friendly and appealing interfaces (UI/UX) to
                    enhance software development with expertise.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 text-center animate-box">
              <div className="services color-3">
                <span className="icon">
                  <i className="icon-phone3"></i>
                </span>
                <div className="desc">
                  <h3>Software Development</h3>
                  <p>
                    Creating robust web and mobile softwares with a focus on
                    user needs and cutting-edge technology.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 text-center animate-box">
              <div className="services color-5">
                <span className="icon">
                  <i className="icon-data"></i>
                </span>
                <div className="desc">
                  <h3>Testing & Optimization</h3>
                  <p>
                    Specializing in rigorous testing and optimization for
                    high-performance, seamless user experiences.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 text-center animate-box">
              <div className="services color-6">
                <span className="icon">
                  <i className="icon-stack3"></i>
                </span>
                <div className="desc">
                  <h3>Maintenance</h3>
                  <p>
                    Ensuring support & regular updates for softwares to maintain
                    performance & user satisfaction.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div
        id="colorlib-counter"
        className="colorlib-counters"
        style={{
          backgroundImage: `url('${process.env.PUBLIC_URL}/assets/images/cover_bg_1.jpg')`,
        }}
        data-stellar-background-ratio="0.5"
      >
        <div className="overlay" />
        <div className="colorlib-narrow-content">
          <div className="row"></div>
          <div className="row">
            <div className="col-md-4 text-center animate-box">
              <span
                className="colorlib-counter js-counter"
                data-from={0}
                data-to={60}
                data-speed={5000}
                data-refresh-interval={60}
              />
              <span className="colorlib-counter-label">Projects</span>
            </div>
            <div className="col-md-4 text-center animate-box">
              <span
                className="colorlib-counter js-counter"
                data-from={0}
                data-to={40}
                data-speed={5000}
                data-refresh-interval={40}
              />
              <span className="colorlib-counter-label">Clients</span>
            </div>
            <div className="col-md-4 text-center animate-box">
              <span
                className="colorlib-counter js-counter"
                data-from={0}
                data-to={10}
                data-speed={5000}
                data-refresh-interval={30}
              />
              <span className="colorlib-counter-label">Partners</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;

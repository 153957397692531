import React from "react";

const Education = () => {
  return (
    <section
      className="colorlib-education"
      data-section="education"
      id="education"
    >
      <div className="colorlib-narrow-content">
        <div className="row">
          <div
            className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box"
            data-animate-effect="fadeInLeft"
          >
            <span className="heading-meta">Education</span>
            <h2 className="colorlib-heading animate-box">Education</h2>
          </div>
        </div>
        <div className="row">
          <div
            className="col-md-12 animate-box"
            data-animate-effect="fadeInLeft"
          >
            <div className="fancy-collapse-panel">
              <div
                className="panel-group"
                id="accordion"
                role="tablist"
                aria-multiselectable="true"
              >
                <div className="panel panel-default">
                  <div className="panel-heading" role="tab" id="headingOne">
                    <h4 className="panel-title">
                      <a
                        data-toggle="collapse"
                        data-parent="#accordion"
                        href="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        Master of Computer Applications:
                      </a>
                    </h4>
                  </div>
                  <div
                    id="collapseOne"
                    className="panel-collapse collapse in"
                    role="tabpanel"
                    aria-labelledby="headingOne"
                  >
                    <div className="panel-body">
                      <p>
                        During my{" "}
                        <strong>
                          Master of Computer Applications (MCA) degree
                        </strong>
                        , I delved into advanced subjects such as{" "}
                        <strong>software development</strong>,{" "}
                        <strong>database management systems</strong>, and{" "}
                        <strong>computer networks</strong>.
                      </p>
                        <ul>
                          <li>
                            {" "}
                            I also explored <strong>
                              web technologies
                            </strong>, <strong>distributed computing</strong>,{" "}
                            <strong>advanced algorithms</strong>, and{" "}
                            <strong>mobile technology</strong>. Specialized
                            electives in <strong>machine learning</strong>,
                            <strong>cloud computing</strong>, and{" "}
                            <strong>cybersecurity</strong> further enriched my
                            knowledge.
                          </li>
                          <li>
                            This program equipped me with a comprehensive and
                            robust understanding of both theoretical concepts
                            and practical applications in the field of{" "}
                            <strong>computer science</strong>.
                          </li>
                        </ul>
                    </div>
                  </div>
                </div>
                <div className="panel panel-default">
                  <div className="panel-heading" role="tab" id="headingTwo">
                    <h4 className="panel-title">
                      <a
                        className="collapsed"
                        data-toggle="collapse"
                        data-parent="#accordion"
                        href="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        Bachelor of Computer Science:
                      </a>
                    </h4>
                  </div>
                  <div
                    id="collapseTwo"
                    className="panel-collapse collapse"
                    role="tabpanel"
                    aria-labelledby="headingTwo"
                  >
                    <div className="panel-body">
                      <p>
                        During my{" "}
                        <strong>Bachelor of Computer Science degree</strong>, I
                        studied core subjects such as{" "}
                        <strong>algorithms</strong>,{" "}
                        <strong>data structures</strong>,
                        <strong>computer architecture</strong>, and{" "}
                        <strong>operating systems</strong>.
                      </p>
                        <ul>
                          <li>
                            I also delved into{" "}
                            <strong>software engineering</strong>,{" "}
                            <strong>database management</strong>,
                            <strong>artificial intelligence</strong>, and{" "}
                            <strong>cybersecurity</strong>.
                          </li>
                          <li>
                            Elective courses allowed me to explore specialized
                            areas like
                            <strong>machine learning</strong>,{" "}
                            <strong>web development</strong>, and{" "}
                            <strong>mobile app development</strong>, providing a
                            comprehensive and robust foundation in both
                            theoretical and practical aspects of
                            <strong> computer science</strong>.
                          </li>
                        </ul>
                    </div>
                  </div>
                </div>
                <div className="panel panel-default">
                  <div className="panel-heading" role="tab" id="headingFour">
                    <h4 className="panel-title">
                      <a
                        className="collapsed"
                        data-toggle="collapse"
                        data-parent="#accordion"
                        href="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseFour"
                      >
                        High school secondary education:
                      </a>
                    </h4>
                  </div>
                  <div
                    id="collapseFour"
                    className="panel-collapse collapse"
                    role="tabpanel"
                    aria-labelledby="headingFour"
                  >
                    <div className="panel-body">
                      <p>
                        In <strong>high school</strong>, I studied a variety of
                        subjects, including{" "}
                        <strong>
                          {" "}
                          Mathematics (Algebra, Geometry, Calculus)
                        </strong>
                        ,
                        <strong> Sciences (Biology, Chemistry, Physics)</strong>
                        , <strong> English Language Arts</strong>, and{" "}
                        <strong> Social Studies (History, Government)</strong>.
                      </p>
                        <ul>
                          <li>
                            {" "}
                            I also participated in{" "}
                            <strong> Physical Education </strong>, and explored
                            electives like{" "}
                            <strong> Computer Programming </strong> and{" "}
                            <strong> Visual Arts </strong>, which provided a
                            well-rounded foundation for my{" "}
                            <strong> academic </strong> 
                            and <strong> professional future </strong>.
                          </li>
                        </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Education;

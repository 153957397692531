// Aside.js
import React, { useState } from "react";

const Aside = () => {
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <a href="#" className="js-colorlib-nav-toggle colorlib-nav-toggle" dataToggle="collapse"
       dataTarget="#navbar" ariaExpanded="false" ariaControls="navbar"><i></i></a>
    <aside
      id="colorlib-aside"
      role="complementary"
      className="border js-fullheight"
    >
      <div className="text-center">
        <div
          className="author-img"
          style={{
            backgroundImage: `url('${process.env.PUBLIC_URL}/assets/images/about.jpg')`,
          }}
        />
        <h1 id="colorlib-logo">
          <a href="/">Pradeep Singh</a>
        </h1>
        <span className="position">
          <a href="#">Sr. Software engineer</a> in India <br />
          (iOS, Android & Flutter)
        </span>
      </div>
      <nav id="colorlib-main-menu" role="navigation" className="navbar">
        <div id="navbar" className="collapse">
          <ul>
            <li className="active">
              <a
                href="#home"
                data-nav-section="home"
                onClick={() => scrollToSection("colorlib-hero")}
              >
                Home
              </a>
            </li>
            <li className="">
              <a
                href="#about"
                data-nav-section="about"
                onClick={() => scrollToSection("about")}
              >
                About
              </a>
            </li>
            <li className="">
              <a
                href="#services"
                data-nav-section="services"
                onClick={() => scrollToSection("services")}
              >
                Services
              </a>
            </li>
            <li className="">
              <a
                href="#skills"
                data-nav-section="skills"
                onClick={() => scrollToSection("skills")}
              >
                Skills
              </a>
            </li>
            <li className="">
              <a
                href="#education"
                data-nav-section="education"
                onClick={() => scrollToSection("education")}
              >
                Education
              </a>
            </li>
            <li className="">
              <a
                href="#experience"
                data-nav-section="experience"
                onClick={() => scrollToSection("experience")}
              >
                Experience
              </a>
            </li>
            <li className="">
              <a
                href="#work"
                data-nav-section="work"
                onClick={() => scrollToSection("work")}
              >
                Work
              </a>
            </li>
            <li className="">
              <a
                href="#certifications"
                data-nav-section="certifications"
                onClick={() => scrollToSection("certifications")}
              >
                Certifications
              </a>
            </li>
            <li className="">
              <a
                href="#achivements"
                data-nav-section="achivements"
                onClick={() => scrollToSection("achivements")}
              >
                Achivements
              </a>
            </li>
            <li className="">
              <a
                href="#contact"
                data-nav-section="contact"
                onClick={() => scrollToSection("contact")}
              >
                Contact
              </a>
            </li>
          </ul>
        </div>
      </nav>
      <div className="colorlib-footer">
        <p>
          <small>
            ©
            {/* Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. */}
            Copyright All rights reserved.
          </small>
        </p>
        <ul className="text-center">
          <li>
            <a
              href="https://stackoverflow.com/users/8201371/pradeep-singh?tab=profile"
              target="_blank"
              style={{ color: "black" }}
            >
              <i className="icon-stackoverflow" />
            </a>
          </li>
        </ul>
      </div>
    </aside>
    </>
  );
};

export default Aside;

import React from "react";

const Experience = () => {
  return (
    <section
      className="colorlib-experience"
      data-section="experience"
      id="experience"
    >
      <div className="colorlib-narrow-content">
        <div className="row">
          <div
            className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box"
            data-animate-effect="fadeInLeft"
          >
            <span className="heading-meta">Experience</span>
            <h2 className="colorlib-heading animate-box">
              Work Experience
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="timeline-centered">
              <article
                className="timeline-entry animate-box"
                data-animate-effect="fadeInLeft"
              >
                <div className="timeline-entry-inner">
                  <div className="timeline-icon color-1">
                    <i className="icon-pen2" />
                  </div>
                  <div className="timeline-label">
                    <h2>
                      <a href="#">Sr. Software Engineer</a>{" "}
                      <span>2021- present</span>
                    </h2>
                    <p>
                      As a Senior Software Engineer specializing in multiple
                      technologies from 2021 to present, I excelled in designing
                      and developing robust backend systems using PHP with
                      Laravel framework and Java, while also creating responsive
                      and interactive frontend interfaces with React.js.
                      Additionally, I leveraged my skills as a System Analyst to
                      gather and refine requirements, design scalable system
                      architectures, and ensure seamless integration across
                      various tech stacks.
                    </p>
                    <p> My responsibilities included leading
                      cross-functional teams, implementing best practices in
                      software engineering, and delivering high-quality
                      solutions that met both technical and business objectives.
                      This role allowed me to expand my expertise in diverse
                      technologies and effectively contribute to complex project
                      lifecycles.</p>
                  </div>
                </div>
              </article>
              <article
                className="timeline-entry animate-box"
                data-animate-effect="fadeInRight"
              >
                <div className="timeline-entry-inner">
                  <div className="timeline-icon color-2">
                    <i className="icon-pen2" />
                  </div>
                  <div className="timeline-label">
                    <h2>
                      <a href="#">Senior Mobile Developer</a>{" "}
                      <span>2018-2021</span>
                    </h2>
                    <p>
                      As a Senior Mobile Developer specializing in Android, iOS,
                      and Flutter from 2018 to 2021, I led the development of
                      cutting-edge mobile applications across multiple
                      platforms. I leveraged my expertise in Java and Kotlin for
                      Android, Swift and Objective-C for iOS, and Dart for
                      Flutter to create robust and user-friendly mobile
                      solutions. I collaborated closely with cross-functional
                      teams to design, implement, and optimize mobile
                      applications, ensuring they met high standards of
                      performance, security, and usability. This role allowed me
                      to deepen my proficiency in mobile development frameworks
                      and deliver innovative solutions that enhanced user
                      experiences and business outcomes.
                    </p>
                  </div>
                </div>
              </article>
              <article
                className="timeline-entry animate-box"
                data-animate-effect="fadeInLeft"
              >
                <div className="timeline-entry-inner">
                  <div className="timeline-icon color-3">
                    <i className="icon-pen2" />
                  </div>
                  <div className="timeline-label">
                    <h2>
                      <a href="#">Jr iOS Developer </a> <span> 2015-2018</span>
                    </h2>
                    <p>
                      As a Jr. iOS Developer from 2015 to 2018, I gained
                      hands-on experience in Swift and Objective-C, developed
                      and maintained iOS applications, and honed my skills in
                      UI/UX design. I also learned to collaborate effectively
                      with cross-functional teams, debug and optimize code, and
                      stay updated with the latest iOS trends and best
                      practices. This period was crucial in building a solid
                      foundation for my career in mobile app development.
                    </p>
                  </div>
                </div>
              </article>
              <article
                className="timeline-entry begin animate-box"
                data-animate-effect="fadeInBottom"
              >
                <div className="timeline-entry-inner">
                  <div className="timeline-icon color-none"></div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Experience;

import React, { useState, useEffect } from "react";
import Contact from "./Contact"; // Import the Contact component

const About = () => {
  const [showContact, setShowContact] = useState(false);

  const scrollToContact = () => {
    // Smooth scrolling to the contact section
    const contactSection = document.getElementById("contact");
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleScroll = () => {
    const contactSection = document.getElementById("contact");
    if (contactSection) {
      const rect = contactSection.getBoundingClientRect();
      const isVisible = rect.top >= 0 && rect.bottom <= window.innerHeight;
      if (!isVisible) {
        setShowContact(false);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section className="colorlib-about" data-section="about" id="about">
      <div className="colorlib-narrow-content">
        <div className="row">
          <div className="col-md-12">
            <div
              className="row row-bottom-padded-sm animate-box"
              data-animate-effect="fadeInLeft"
            >
              <div className="col-md-12">
                <div className="about-desc">
                  <span className="heading-meta">About Us</span>
                  <h2 className="colorlib-heading">Who Am I?</h2>
                  <p>
                    <strong>Hi I'm Pradeep Singh</strong> a passionate software
                    engineer dedicated to crafting elegant and efficient code.
                    With a deep understanding of algorithms, data structures,
                    and software design principles, I thrive on solving complex
                    problems and creating innovative solutions. My expertise
                    spans across various programming languages and technologies,
                    enabling me to adapt and excel in diverse projects.
                  </p>
                  <p>
                    I am committed to continuous learning and staying abreast of
                    industry trends to deliver high-quality software that meets
                    user needs. Collaboration, creativity, and attention to
                    detail define my approach to software development.
                  </p>
                  <p>
                    Participated in programming of Social Media, eCommerce,
                    Healthcare, POS Software, Cab booking, eSignature platforms,
                    Location Services, Social Networking, Offline Apps, Time
                    tracking, Online service booking, Datiing App, Custom SDKs.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div
                className="col-md-3 animate-box"
                data-animate-effect="fadeInLeft"
              >
                <div className="services color-1">
                  <span className="icon2">
                    <i className="icon-bulb" />
                  </span>
                  <h3>UI/UX</h3>
                </div>
              </div>
              <div
                className="col-md-3 animate-box"
                data-animate-effect="fadeInRight"
              >
                <div className="services color-2">
                  <span className="icon2">
                    <i className="icon-globe-outline" />
                  </span>
                  <h3>Web Design</h3>
                </div>
              </div>
              <div
                className="col-md-3 animate-box"
                data-animate-effect="fadeInTop"
              >
                <div className="services color-3">
                  <span className="icon2">
                    <i className="icon-data" />
                  </span>
                  <h3>Custom Software</h3>
                </div>
              </div>
              <div
                className="col-md-3 animate-box"
                data-animate-effect="fadeInBottom"
              >
                <div className="services color-4">
                  <span className="icon2">
                    <i className="icon-phone3" />
                  </span>
                  <h3 style={{ width: "170px" }}>Mobile Application</h3>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12" style={{marginBottom: "30px"}}>
                <ul className="mark">
                  <li>
                    Over 8 years of experience in application software
                    development for mobile platforms.
                  </li>
                  <li>
                    Proficient in Objective-C, Swift, Kotlin, Java, SQL, and
                    Dart programming languages.
                  </li>
                  <li>
                    Well-versed with Agile Methodology(Scrum), SDLC, OOPs.
                  </li>
                  <li>
                    Experience with different architecture implementations, such
                    as MVC and MVVM.
                  </li>
                  <li>
                    Cloning and adapting existing Android apps to iOS platform
                    and vice versa.
                  </li>
                  <li>
                    Experience with the Flutter framework to develop hybrid
                    applications.
                  </li>
                  <li>
                    Experience with backend development for applications using
                    BaaS, such as Firebase.
                  </li>
                  <li>
                    Experience with offline storage, threading, and performance
                    tuning.
                  </li>
                  <li>
                    Familiarity with RESTful APIs to connect mobile applications
                    to back-end services.
                  </li>
                  <li>
                    Knowledge of UI/UX standards and tools such as Figma,
                    Zeplin, and Wireframe.
                  </li>
                  <li>Experience with performance and memory tuning tools.</li>
                  <li>
                    Familiarity with cloud messaging APIs and push
                    notifications.
                  </li>
                  <li>
                    Proficient understanding of code versioning tools such as
                    Git, GitHub, Bitbucket, and SourceTree.
                  </li>
                  <li>Experience in developing custom SDKs or frameworks.</li>
                  <li>
                    Experience with third-party social network integration
                    (Facebook, Google, Apple, Dropbox, etc.).
                  </li>
                  <li>
                    Experience with ad network and analytics integration (AdMob,
                    Crashlytics, etc.).{" "}
                  </li>
                  <li>
                    Experience with Twilio to create SIP domains for calling
                    using softphones.
                  </li>
                  <li>Experience with Jira and Fastlane tools.</li>
                  <li>
                    Experience with Unit Testing, UI Testing, Debugging, Code
                    Coverage and XCTest.
                  </li>
                  <li>
                    Experience to develop the KIOSK applications and managed
                    with JAMF(MDM).
                  </li>
                  <li>
                    Knowledge of React JS, PHP and Laravel fremework as well.
                  </li>
                </ul>
              </div>
            </div>
            <div className="row">
              <div
                className="col-md-12 animate-box"
                data-animate-effect="fadeInLeft"
              >
                <div className="hire">
                  <h2>
                  I am excited to share that  <br/> I have successfully delivered approx 60+ projects!
                  </h2>
                  {/* Modify here to call scrollToContact function */}
                  <a
                    href="#contact"
                    className="btn-hire"
                    onClick={scrollToContact}
                  >
                    Hire me
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Conditional rendering of the Contact component */}
      {showContact && <Contact />}
    </section>
  );
};

export default About;

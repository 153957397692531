import React from "react";

const Home = () => {
  const handleDownload = () => {
    const pdfUrl = `${process.env.PUBLIC_URL}/assets/pradeep-resume.pdf`;

    const link = document.createElement("a");
    link.href = pdfUrl;
    link.setAttribute("download", "pradeep-resume.pdf");

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleViewPortfolio = () => {
    // Smooth scrolling to the Portfolio section
    const portfolioSection = document.getElementById("work");
    if (portfolioSection) {
      portfolioSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <section id="colorlib-hero" className="js-fullheight" data-section="home">
      <div className="flexslider js-fullheight">
        <ul className="slides">
          <li
            style={{
              backgroundImage: `url('${process.env.PUBLIC_URL}/assets/images/img_bg_1.jpg')`,
            }}
          >
            <div className="overlay" />
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-9 col-md-offset-3 col-md-pull-3 col-sm-12 col-xs-12 js-fullheight" style={{height:"100vh",display:"flex"}}>
                  <div className="slider-text-inner js-fullheight" style={{display:"flex",flexGrow:1,justifyContent:"center",flexDirection:'column'}}>
                    <div className="desc">
                      <h1>
                        Hi! <br />
                        I'm Pradeep
                      </h1>
                      <p>
                        <button
                          className="btn btn-primary btn-learn"
                          onClick={handleDownload}
                        >
                          Download CV <i className="icon-download4" />
                        </button>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>

          <li
            style={{
              backgroundImage: `url('${process.env.PUBLIC_URL}/assets/images/img_bg_2.jpg')`, 
            }}
          >
            <div className="overlay" />
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-9 col-md-offset-3 col-md-pull-3 col-sm-12 col-xs-12 js-fullheight" style={{height:"100vh",display:"flex"}}>
                  <div className="slider-text-inner js-fullheight" style={{display:"flex",flexGrow:1,justifyContent:"center",flexDirection:'column'}}>
                    <div className="desc">
                      <h1>
                      I am a<br /> Mobile Developer
                      </h1>
                      <p>
                        <button
                          className="btn btn-primary btn-learn"
                          onClick={handleViewPortfolio}
                          >
                            View Portfolio <i className="icon-briefcase3" />
                        </button>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Home;
